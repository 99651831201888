/* eslint-disable no-console */
import { loadSentry } from '@/domains/core/observability/loadSentry';

import { IS_PROD } from '../../platform/constants';
import type { Logger } from './Logger';

export default {
  error(error: Error) {
    if (IS_PROD) {
      loadSentry()
        .then((Sentry) => {
          Sentry.captureException(error);
        })
        .catch((err) =>
          console.error('Cannot load Sentry in clientSideLogger:', err),
        );
    } else {
      console.error(error);
    }
  },
} as Logger;
