const databrowser = [
  { name: 'Opera', value: 'OPR', version: 'OPR' },
  { name: 'Edge', value: 'Edg', version: 'Edg' },
  { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
  { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
  { name: 'Safari', value: 'Safari', version: 'Version' },
];

const matchItem = (string: string, data: any) => {
  let regex;
  let regexv;
  let match;
  let matches;
  let version;

  if (
    // eslint-disable-next-line no-underscore-dangle
    window.__NEXT_DATA__?.props.pageProps.initialReduxState?.core.client
      .isMobileAppUserAgent
  ) {
    return { name: 'ManoManoMobileApp', version: 0 };
  }

  // For test env
  if (string.includes('jsdom')) {
    return { name: 'browserTest', version: 0 };
  }

  for (const e of data) {
    regex = new RegExp(e.value, 'i');
    match = regex.test(string);

    if (match) {
      regexv = new RegExp(`${e.version}[- /:;]([\\d._]+)`, 'i');
      matches = string.match(regexv);
      version = '';
      if (matches) {
        if (matches[1]) {
          matches = matches[1];
        }
      }
      version = matches ? (matches as string).replace('/[/_]+/g', '.') : '0';
      return {
        name: e.name,
        version: parseFloat(version),
        originalVersion: version,
      };
    }
  }
  return { name: 'unknown', version: 0 };
};

export function getBrowserInfo() {
  return matchItem(navigator.userAgent, databrowser);
}
