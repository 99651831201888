import { loadSentry } from '@/domains/core/observability/loadSentry';
import logger from '@/domains/core/observability/logger';
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';
import { useAuth } from '@/domains/customerManagement/auth/hooks/useAuth';

export const StorePageUrlInSession = () => {
  const { isAuthenticated } = useAuth();
  if (IS_CLIENT_SIDE) {
    // Needed for google signin
    if (!isAuthenticated) {
      try {
        sessionStorage.setItem('g_redirect', window.location.href);
      } catch (error) {
        loadSentry()
          .then((Sentry) => {
            Sentry.withScope((scope) => {
              scope.setTag('core', 'basepage');
              Sentry.captureException(error);
            });
          })
          .catch(() => logger.error(error));
      }
    }
  }
  return null;
};
