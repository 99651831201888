import Script from 'next/script';
import type { FunctionComponent } from 'react';

import { loadSentry } from '@/domains/core/observability/loadSentry';

import { getAppSetting } from '../../settings/appSettings';

export const GtmScript: FunctionComponent = () => {
  const GTM_URL = getAppSetting('EXTERNAL_TAG_GTM_URL');
  const GTM_ID = getAppSetting('EXTERNAL_TAG_GTM_ID');

  if (typeof window !== 'undefined' && window.__gtmExec__ === undefined) {
    window.__gtmExec__ = async (code, gtmTagDebugId) => {
      try {
        // eslint-disable-next-line no-eval
        eval(code);
      } catch (error) {
        const Sentry = await loadSentry();

        Sentry.captureException(error, {
          extra: { gtmTagDebugId },
          tags: { fromGTM: true },
        });
      }
    };
  }

  return (
    <Script
      id="external-tag-gtm-head"
      data-testid="external-tag-gtm-head"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'${GTM_URL}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');`,
      }}
    />
  );
};
