import { loadSentry } from './loadSentry';

/**
 * This file aims to initialize Sentry only when an error occurs.
 * We don't want to load Sentry on app initialization since the bundle is too
 * big and cannot be tree shaked.
 * To do so, we capture the onerror and onunhandledrejection window events, and
 * also the console.error. Once these events are triggered, we lazy load and
 * init Sentry.
 */

enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical',
}
type Level = 'log' | 'info' | 'warn' | 'error' | 'debug' | 'assert';

/**
 * @see https://github.com/getsentry/sentry-javascript/blob/master/packages/types/src/severity.ts
 */
function getSeverityFromString(level: string): Severity {
  switch (level) {
    case 'debug':
      return Severity.Debug;
    case 'info':
      return Severity.Info;
    case 'warn':
    case 'warning':
      return Severity.Warning;
    case 'error':
      return Severity.Error;
    case 'fatal':
      return Severity.Fatal;
    case 'critical':
      return Severity.Critical;
    case 'log':
    default:
      return Severity.Log;
  }
}

/**
 * @see https://github.com/getsentry/sentry-javascript/blob/master/packages/utils/src/string.ts
 */
function safeJoin(input: any[], delimiter?: string): string {
  if (!Array.isArray(input)) {
    return '';
  }

  const output = [];
  for (let i = 0; i < input.length; i += 1) {
    const value = input[i];
    try {
      output.push(String(value));
    } catch (e) {
      output.push('[value cannot be serialized]');
    }
  }

  return output.join(delimiter);
}

/**
 * Inspired from https://github.com/getsentry/sentry-javascript/blob/master/packages/integrations/src/captureconsole.ts
 */
function captureConsole(levels: Level[]) {
  levels.forEach((level) => {
    // eslint-disable-next-line no-console
    if (!(level in console) && typeof console[level] === 'function') {
      return;
    }

    // eslint-disable-next-line no-console
    const original = console[level];
    const wrapped = (
      (originalConsoleLevel: () => any) =>
      (...args: any[]): void => {
        loadSentry().then((Sentry) => {
          Sentry.withScope((scope) => {
            scope.setLevel(getSeverityFromString(level));
            scope.setExtra('arguments', args);
            scope.addEventProcessor((event) => ({
              ...event,
              logger: 'console',
            }));
            Sentry.captureMessage(safeJoin(args, ' '));
          });
        });

        // this fails for some browsers. :(
        if (originalConsoleLevel) {
          Function.prototype.apply.call(
            originalConsoleLevel,
            global.console,
            args,
          );
        }
      }
    )(original);

    // eslint-disable-next-line no-console
    console[level] = wrapped;
  });
}

/**
 * Inspired from https://github.com/getsentry/sentry-javascript/blob/master/packages/browser/src/loader.js
 */
function captureWindowErrors() {
  const originalOnError = window.onerror;
  const originalOnUnHandledRejection = window.onunhandledrejection;
  const queue: {
    errors: unknown[];
    rejections: unknown[];
  } = {
    errors: [],
    rejections: [],
  };
  let isReset = false;

  function load() {
    if (isReset) {
      return;
    }

    isReset = true;

    loadSentry(() => {
      window.onerror = originalOnError;
      window.onunhandledrejection = originalOnUnHandledRejection;
    })
      .then(() => {
        queue.errors.forEach((dataArgs: any) => {
          // eslint-disable-next-line prefer-spread
          window.onerror?.apply(window, dataArgs as any);
        });
        queue.rejections.forEach((dataArgs: any) => {
          // eslint-disable-next-line prefer-spread
          window.onunhandledrejection?.apply(window, dataArgs as any);
        });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error('Cannot init Sentry.', err));
  }

  // Beware: on development env, errors are thrown twice due to the error catching of Next.js
  window.onerror = (...args: any[]) => {
    queue.errors.push(args);

    if (originalOnError) {
      originalOnError.apply(window, args as any);
    }

    load();
  };

  window.onunhandledrejection = (event: PromiseRejectionEvent) => {
    queue.rejections.push([event]);

    if (originalOnUnHandledRejection) {
      originalOnUnHandledRejection.apply(window, [event]);
    }

    load();
  };
}

export function initSentry() {
  if (typeof window !== 'undefined') {
    captureConsole(['error']);
    captureWindowErrors();
  }
}
