import type { NextPage } from 'next';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { useSsrStylesCleaner } from '@/core/page/hooks/useSsrStylesCleaner';
import type {
  PageProps,
  PageWithCustomLayout,
} from '@/domains/core/page/interfaces/PageProps';

import 'config/tailwind/tailwind.css';
import '@/core/page/styles/app.scss';

import { ABTastyScript } from '@/domains/core/ABTasty/ABTastyScript';
import { BatchScript } from '@/domains/core/Batch/BatchScript';
import { CSide } from '@/domains/core/CSide/CsideScript';
import { initSentry } from '@/domains/core/observability/initSentry';
import { WebVitalsReporter } from '@/domains/core/observability/webVitals/WebVitalsReporter';
import { BaseProvider } from '@/domains/core/page/components/BaseProvider';
import { ReactScanMonitoringScript } from '@/domains/core/reactMonitoring/ReactScanMonitoringScript';
import { RoutingBroadcaster } from '@/domains/core/routing/broadcaster/components/RoutingBroadcaster';
import { StorePageUrlInSession } from '@/domains/core/routing/recorder/StorePageUrlInSession';
import { IS_CLIENT_SIDE } from '@/domains/core/settings/constants';
import { getRootActions } from '@/domains/core/shell/applicationState/root.actions';
import { staticReducers } from '@/domains/core/shell/applicationState/root.reducer.default';
import { buildRootSaga } from '@/domains/core/shell/applicationState/root.saga.default';
import { GtmScript } from '@/domains/core/tracking/components/GtmScript';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { TrustBadge } from '@/domains/core/trustedShops/TrustBadge.default';
import { SessionProvider } from '@/domains/customerManagement/auth/components/SessionProvider/SessionProvider';
import { cleanOldCookies } from '@/domains/legal/GDPR/utils/cleanOldCookies';
import { registerDidomiBannerDecorator } from '@/domains/legal/GDPR/utils/registerDidomiBannerDecorator';

if (
  IS_CLIENT_SIDE &&
  (!('scheduler' in window) ||
    !('postTask' in (window.scheduler as Record<string, unknown>)) ||
    !('yield' in (window.scheduler as Record<string, unknown>)))
) {
  import('scheduler-polyfill');
}

const BaseLayout = dynamic(() =>
  import('@/core/shell/page/BaseLayout').then((mod) => mod.BaseLayout),
);
const FunnelLayout = dynamic(() =>
  import('@/core/shell/page/FunnelLayout').then((mod) => mod.FunnelLayout),
);

initSentry();

let webVitalsReporter: WebVitalsReporter;

function getWebVitalsReporter() {
  if (webVitalsReporter === undefined) {
    webVitalsReporter = WebVitalsReporter.create();
  }

  return webVitalsReporter;
}

interface Props extends AppProps<PageProps> {
  Component: NextPage<PageProps>;
}

function applyLayout(
  PageComponent: PageWithCustomLayout<PageProps>,
  pageProps: PageProps,
) {
  switch (pageProps.layout) {
    case 'none':
      return <PageComponent {...pageProps} />;
    case 'funnel':
      return (
        <FunnelLayout {...pageProps}>
          <PageComponent {...pageProps} />
        </FunnelLayout>
      );
    case 'custom':
      if (!PageComponent.Layout)
        throw new Error(
          'A page with a custom layout needs to have a Layout property',
        );
      return PageComponent.Layout(<PageComponent {...pageProps} />, pageProps);
    default:
      return (
        <BaseLayout {...pageProps}>
          <PageComponent {...pageProps} />
        </BaseLayout>
      );
  }
}

function MyApp({ Component: PageComponent, pageProps, router }: Props) {
  useEffect(() => {
    const unregisterHandlers = getWebVitalsReporter().registerHandlers();

    return () => {
      unregisterHandlers();
    };
  }, []);

  const [startupActions] = useState(() => getRootActions());
  useSsrStylesCleaner();

  useEffect(() => {
    Gtm.listenForConsent();
    cleanOldCookies();
    registerDidomiBannerDecorator();

    window.requestIdleCallback(() => {
      // preload layouts
      import('@/core/shell/page/BaseLayout');
      import('@/core/shell/page/FunnelLayout');
    });
  }, []);

  return (
    <BaseProvider
      {...pageProps}
      startupActions={startupActions}
      startupReducers={staticReducers}
      startupSagas={buildRootSaga()}
      router={router}
      flags={pageProps.initialReduxState?.core.flags.flagValuesMap}
    >
      <SessionProvider>
        <CSide />
        <TrustBadge />
        <StorePageUrlInSession />
        <RoutingBroadcaster />
        {applyLayout(PageComponent, pageProps)}
        {pageProps.enableThirdParties && <GtmScript />}
        {pageProps.enableThirdParties && <ABTastyScript />}
        {pageProps.enableThirdParties && <BatchScript />}
        <ReactScanMonitoringScript />
      </SessionProvider>
    </BaseProvider>
  );
}

// We still use this old way of reporting web vitals because the new way
// using `use-report-web-vitals` has this bug for which it won't report
// custom next.js metrics https://github.com/vercel/next.js/issues/63657
// On top of this, this web-vitals implementations via this function seems
// to be buggy as is not reporting all the metrics reported by the `web-vitals` library.
// That's why we use the `web-vitals` library directly in the `WebVitalsReporter` class
// and this functions only for custom nextjs metrics.
export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (metric.label === 'custom') {
    getWebVitalsReporter().submitNextMetric(metric);
  }
}

export default MyApp;
